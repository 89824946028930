import { useEffect, useState, useMemo, memo } from 'react'
import { ConfigurableCartItem, useFetchCartHeaderQuery } from '@magentoTypes'
import { createMagentoClient } from '~/graphql/magentoClient'
import { useCartId, getCartId } from '~/hooks/useCartId'
import { toast } from 'react-toastify'
import { parseMagentoGraphqlError, resettableErrors } from '~/lib/parseMagentoGraphlError'
import { Icon } from '~/elements/Icons/Icon'
import clsx from 'clsx'

type CartButtonProps = {
  magentoClient: ReturnType<typeof createMagentoClient>
  lightTheme?: boolean
}

const useCartInsight = (data: any, identified: boolean) => {
  useEffect(() => {
    if (!process.env.NEXT_PUBLIC_DOTDIGITAL_PROGRAM_ID || !data?.cart?.email) return

    if (!identified) {
      ;(window as any)?.dmPt('identify', data.cart.email)
    }

    if (!data?.cart?.total_quantity) return

    const lineItems = data.cart.items.map((item: ConfigurableCartItem) => ({
      sku: item?.configured_variant?.sku,
      name: item?.product?.name,
      unitPrice: item?.configured_variant?.price_range?.minimum_price?.regular_price?.value,
      salePrice: item?.configured_variant?.price_range?.minimum_price?.final_price?.value,
      quantity: item?.quantity,
      totalPrice: item?.prices?.row_total_including_tax?.value,
      imageUrl: item?.product?.image?.url,
      productUrl: `https://${
        process.env.NEXT_PUBLIC_VERCEL_ENV == 'production'
          ? process.env.NEXT_PUBLIC_DOMAIN_NAME
          : process.env.NEXT_PUBLIC_VERCEL_URL
      }/${item?.product?.url_rewrites?.[0]?.url}`,
    }))

    const selectedShippingMethod = data.cart?.shipping_addresses?.[0]?.selected_shipping_method
    const selectedShippingCosts = selectedShippingMethod?.amount
    const selectedShippingCostsInclTax =
      selectedShippingMethod?.price_incl_tax ?? selectedShippingCosts

    const grandTotalWithoutDiscount = data?.cart?.prices?.subtotal_including_tax?.value
      ? (selectedShippingCostsInclTax?.value || 0) +
        data?.cart?.prices?.subtotal_including_tax?.value
      : null

    ;(window as any)?.dmPt('cartInsight', {
      programID: process.env.NEXT_PUBLIC_DOTDIGITAL_PROGRAM_ID,
      cartDelay: parseInt((process.env.NEXT_PUBLIC_DOTDIGITAL_CART_DELAY || '30') as string),
      cartID: data?.cart?.id,
      cartPhase: 'CUSTOMER_LOGIN',
      currency: data?.cart?.prices?.subtotal_including_tax?.currency || 'EUR',
      subtotal: data?.cart?.prices?.subtotal_including_tax?.value,
      grandTotal: data?.cart?.prices?.grand_total?.value,
      shipping: selectedShippingCostsInclTax?.value || 0,
      discountAmount: Math.abs(
        (data?.cart?.prices?.grand_total?.value as number) - (grandTotalWithoutDiscount as number),
      ),
      taxAmount: data?.cart?.prices?.applied_taxes?.[0]?.amount?.value,
      cartUrl: `https://${
        process.env.NEXT_PUBLIC_VERCEL_ENV == 'production'
          ? process.env.NEXT_PUBLIC_DOMAIN_NAME
          : process.env.NEXT_PUBLIC_VERCEL_URL
      }/cart`,
      lineItems,
    })
  }, [data, identified])
}

export const CartButton = memo(({ magentoClient, lightTheme }: CartButtonProps) => {
  const [cartId, loading, resetCartId] = useCartId(!!getCartId())
  const [identified, setIdentified] = useState(false)

  const { data } = useFetchCartHeaderQuery(
    magentoClient,
    { cartId },
    {
      enabled: !!cartId,
      retry: false,
      onError: async (error) => {
        const parsedError = parseMagentoGraphqlError(error)
        if (
          typeof parsedError?.extensions?.category !== 'undefined' &&
          resettableErrors.includes(parsedError?.extensions?.category)
        ) {
          await resetCartId()
        } else {
          toast.error(JSON.parse(JSON.stringify(error))?.response?.errors?.[0]?.message)
        }
      },
    },
  )

  useCartInsight(data, identified)

  useEffect(() => {
    if (data?.cart?.email) {
      setIdentified(true)
    }
  }, [data?.cart?.email])

  const iconVariant = lightTheme ? 'text-sg-white' : 'text-sg-black'

  return (
    <div className={clsx('relative flex flex-nowrap', iconVariant)}>
      <Icon name="cart" viewBox="0 0 20 20" className="h-5 w-5 lgx:h-[25px] lgx:w-[25px]" />
      {!!data?.cart?.total_quantity && (
        <span
          className={clsx(
            'absolute -top-2.5',
            data.cart.total_quantity > 9 ? '-right-2 lgx:-right-2.5' : '-right-1 lgx:-right-1.5',
            lightTheme && 'text-sg-white',
          )}
        >
          {data.cart.total_quantity}
        </span>
      )}
    </div>
  )
})

CartButton.displayName = 'CartButton'
