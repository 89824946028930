import { useState } from 'react'
import NextLink from 'next/link'
import { BanderoleNewsletter } from '~/modules/BanderoleNewsletter/BanderoleNewsletter'
import { BanderoleTextWithIcon } from '../components/BanderoleTextWithIcon'
import { makeAbsolute } from '~/lib/makeAbsolute'

type DesktopBanderoleNewsletterTextProps = {
  data: {
    disableNewsletterPopup?: boolean | null
    newsletterText?: string | null
    promoText?: string | null
    fullClick?: string | null
    textColor?: string | null
  }
}

export const DesktopBanderoleNewsletterText = ({ data }: DesktopBanderoleNewsletterTextProps) => {
  const [showNewsletterPopup, setShowNewsletterPopup] = useState(false)

  return (
    <>
      <button
        className="flex items-center justify-center font-extralight"
        onClick={() => {
          if (!data?.disableNewsletterPopup) setShowNewsletterPopup(true)
        }}
      >
        <span className="text-[15px] leading-[22px]">
          {!!data.newsletterText && (
            <span className="text-extralight ml-1 text-p text-[15px]">
              <BanderoleTextWithIcon arrowStyles="relative -bottom-1" text={data?.newsletterText} />
            </span>
          )}
        </span>
      </button>
      {!!data.promoText && (
        <span className="text-extralight ml-1 text-p text-[15px]">
          <BanderoleTextWithIcon arrowStyles="relative -bottom-1" text={data?.promoText} />
        </span>
      )}
      {!!data?.fullClick && (
        <NextLink href={makeAbsolute(data?.fullClick as string)} className="absolute inset-0" />
      )}
      <BanderoleNewsletter open={showNewsletterPopup} close={() => setShowNewsletterPopup(false)} />
    </>
  )
}
