import { useTranslation } from 'next-i18next'
import { LanguageButton } from '../../Header/components/LanguageButton'
import clsx from 'clsx'

type DesktopBanderoleInfoProps = {
  className: string
}

export const DesktopBanderoleInfo = ({ className }: DesktopBanderoleInfoProps) => {
  const { t } = useTranslation()

  return (
    <div
      className={clsx(
        'banner-buttons relative flex flex-row items-center justify-end gap-[50px] pr-[70px]',
        className,
      )}
    >
      <a
        className="flex text-[15px] font-extralight leading-[22px]"
        href={t('helpPortalUrl')}
        title={t('Help & Contact')}
      >
        {t('Help & Contact')}
      </a>
      <LanguageButton />
    </div>
  )
}
