import { Link } from '~/elements/Link/Link'
import clsx from 'clsx'
import {
  NavigationElementWithSubElements,
  NavigationElement,
  NavCategoryWithElements,
} from '@contentfulTypes'
import { useNavigation } from '~/lib/Context/NavigationProvider'
import { SearchFlyoutDesktop } from '~/modules/Search/SearchFlyoutDesktop'
import { DataLayerEnum, useGTM } from '~/hooks/useGTM'
import { memo, useCallback, useMemo } from 'react'
import { makeAbsolute } from '~/lib/makeAbsolute'
import { useAutocomplete } from '~/lib/Context/AutocompleteProvider'

interface SecondLevelMenuProps {
  className?: string
  navData?: NavigationElementWithSubElements[]
}

const CategorySubItem = ({
  subItem,
  onNavItemClick,
}: {
  subItem: NavigationElement
  onNavItemClick: (item: NavigationElement) => void
}) => (
  <div className="flex items-center">
    {subItem.colorful && (
      <span className="mr-[15px] inline-block h-[15px] w-[15px] rounded-sm bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-[#ADFF00] via-[#E964FF] to-[#00A3FF]" />
    )}
    {subItem.colorHex && (
      <span
        className="mr-[15px] inline-block h-[15px] w-[15px] rounded-sm"
        style={{ backgroundColor: subItem.colorHex }}
      />
    )}
    <Link
      href={subItem.pageContent?.slug ?? ''}
      prefetch={false}
      className="text-15-25-sg font-extralight text-sg-dark-grey hover:text-sg-black"
      onClick={() => onNavItemClick(subItem)}
    >
      {subItem.title}
    </Link>
  </div>
)

const Category = ({
  category,
  onNavItemClick,
  index,
}: {
  category: NavCategoryWithElements
  onNavItemClick: (item: NavCategoryWithElements | NavigationElement) => void
  index: number
}) => (
  <div className="grid gap-y-2.5 last:mb-0">
    <Link
      href={makeAbsolute((category.pageContent?.slug as string) ?? '')}
      prefetch={false}
      className="text-20-25-sg font-extralight text-sg-black"
      onClick={() => onNavItemClick(category)}
      id={index === 0 ? 'secondLevel' : undefined}
    >
      {category.title}
    </Link>
    <div className="flex flex-col gap-y-2">
      {category?.subElementsCollection?.items?.map(
        (subItem) =>
          subItem?.title && (
            <CategorySubItem
              key={subItem.sys?.id}
              subItem={subItem}
              onNavItemClick={onNavItemClick}
            />
          ),
      )}
    </div>
  </div>
)

export const SecondLevelMenu = ({ navData }: SecondLevelMenuProps) => {
  const { searchValue } = useAutocomplete()
  const { activeLevel, activeLevelIndex, isDesktopFlyoutOpen, isSearchFlyoutOpen } = useNavigation()
  const { dataLayer } = useGTM()

  const firstLevelChildItems = useMemo(
    () => navData?.[activeLevelIndex]?.subElementsCollection?.items,
    [navData, activeLevelIndex],
  )

  const onNavItemClick = useCallback(
    (item: NavigationElement | NavCategoryWithElements) => {
      dataLayer(DataLayerEnum.EVENT, 'nav_item_click', {
        method: 'navigation',
        navigation_level: '2rd_level',
        navigation_item: item?.title ? item?.title : item.subline,
      })
    },
    [dataLayer],
  )

  const renderContent = useCallback(() => {
    if (isSearchFlyoutOpen) {
      return (
        <div className="max-h-[calc(100vh-177px)] w-full overflow-y-auto px-20">
          <SearchFlyoutDesktop query={searchValue ?? ''} />
        </div>
      )
    }

    if (activeLevel === 'first' && firstLevelChildItems) {
      return (
        <nav className="w-full overflow-y-auto overflow-x-hidden px-[30px] pt-[50px] pb-[70px]">
          <ul className="relative grid grid-cols-[repeat(auto-fit,minmax(0,1fr))] gap-x-8">
            {firstLevelChildItems.map(
              (item, index) =>
                item && (
                  <li
                    className="relative col-span-1 flex flex-col gap-y-[25px]"
                    key={item?.sys?.id}
                  >
                    {item?.categoriesCollection?.items?.map(
                      (category, categoryIndex) =>
                        category?.title && (
                          <Category
                            key={category?.sys?.id}
                            category={category}
                            onNavItemClick={onNavItemClick}
                            index={categoryIndex}
                          />
                        ),
                    )}
                  </li>
                ),
            )}
          </ul>
        </nav>
      )
    }

    return null
  }, [isSearchFlyoutOpen, activeLevel, firstLevelChildItems, searchValue, onNavItemClick])

  const menuClasses = clsx(
    'absolute left-0 right-0 overflow-hidden bg-sg-white transition-all duration-300 rounded-b-[5px]',
    {
      'bg-opacity-95 backdrop-blur-[5px]': activeLevel && activeLevelIndex !== -1,
      'opacity-100 translate-y-0': (activeLevel && activeLevelIndex !== -1) || isSearchFlyoutOpen,
      'opacity-0 -translate-y-2': (!activeLevel || activeLevelIndex === -1) && !isSearchFlyoutOpen,
    },
  )

  return (
    <div className={menuClasses}>
      <div
        className={clsx(
          'transition-all duration-500 ease-in-out',
          isDesktopFlyoutOpen || isSearchFlyoutOpen
            ? 'translate-y-0 opacity-100'
            : '-translate-y-4 opacity-0',
        )}
      >
        {(isDesktopFlyoutOpen || isSearchFlyoutOpen) && (
          <div className="h-full">{renderContent()}</div>
        )}
      </div>
    </div>
  )
}
