import React from 'react'
import { useTranslation } from 'next-i18next'
import { useSession } from '~/hooks/useSession'
import { MobileLanguage } from './MobileLanguage'
import { ClientOnly } from '~/elements/ClientOnly/ClientOnly'
import { HeaderButtonBarItem } from '../../components/HeaderButtonBarItem'
import { UserButton } from '../../components/UserButton'

type BottomSectionProps = {
  className?: string
}

export const BottomSection: React.FC<BottomSectionProps> = ({ className = '' }) => {
  const { t } = useTranslation()
  const [session] = useSession()

  return (
    <menu className={`bg-grey-light px-5 py-[30px] ${className}`}>
      <ClientOnly>
        <HeaderButtonBarItem
          href={session ? '/account' : '/login'}
          title={session ? t('account') : t('Login')}
        >
          <span className="flex gap-2.5 font-extralight">
            <UserButton lightTheme={false} />
            <span>{session ? t('account') : t('Login')}</span>
          </span>
        </HeaderButtonBarItem>
      </ClientOnly>
      <li className="py-4 text-15-22-sg">
        <MobileLanguage />
      </li>
    </menu>
  )
}
