import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import Copy from '../../Header/components/Icons/Copy'
import { Icon } from '~/elements/Icons/Icon'
import clsx from 'clsx'

type BanderoleDiscountCodeButtonProps = {
  discountCode: string
  color: string
  className?: string
  small?: boolean
  children?: JSX.Element
}

export const BanderoleDiscountCodeButton = ({
  discountCode,
  color,
  className,
  small = false,
  children,
}: BanderoleDiscountCodeButtonProps) => {
  const { t } = useTranslation()
  const [isPromoCodeCopied, setisPromoCodeCopied] = useState(false)

  const copyToClipboard = () => {
    navigator?.clipboard?.writeText(discountCode as string)
    setisPromoCodeCopied(true)
  }

  return (
    <button
      onClick={() => copyToClipboard()}
      disabled={isPromoCodeCopied}
      className={clsx(
        'relative min-w-[120px] rounded-[5px] bg-sg-black/[.1] ',
        className,
        small ? 'min-h-5 px-2.5' : 'px-2.5 py-[7px]',
      )}
    >
      <div className="flex items-center justify-center font-medium" style={{ color }}>
        {!isPromoCodeCopied ? (
          <>
            <span className="text-xs mr-1 leading-[14px]">{t('CODE')}:</span>
            <span className="text-xs leading-[14px]">{discountCode}</span>
            <Copy className="ml-1 h-[12px] w-[12px]" />
          </>
        ) : (
          <>
            <span className="text-xs leading-[15px]">{t('CODE COPYED')}</span>
            <Icon name="checkmark" className="ml-1 h-4 w-4" />
          </>
        )}
      </div>
      {children}
    </button>
  )
}
