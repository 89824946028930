import { useRouter } from 'next/router'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { Newsletter, usePageNewsletterQuery } from '@contentfulTypes'
import { DEFAULT_LOCALE } from '~/config/constants'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'

export const useNewsletterData = () => {
  const router = useRouter()
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const { data: newsletterData } = usePageNewsletterQuery(contentfulClient, {
    preview,
    locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
  })

  const { headline, imageDesktop, imageMobile, disclaimer, subscriptionBenefits } =
    (newsletterData?.pageCollection?.items[0]?.pageNewsletter as Newsletter) || {}

  const benefits = (subscriptionBenefits || []).filter((benefit) => benefit !== null)

  return { headline, benefits, imageDesktop, imageMobile, disclaimer }
}
