import { useGetWishlistIdQuery } from '@magentoTypes'
import { useQueryClient } from '@tanstack/react-query'
import { signOut } from 'next-auth/react'
import { CART_ID } from '~/config/constants'
import { ClientOnly } from '~/elements/ClientOnly/ClientOnly'
import { createMagentoClient } from '~/graphql/magentoClient'
import useLocalStorage from '~/hooks/useLocalStorage'
import type { CostumeSession } from '~/hooks/useSession'
import { Icon } from '~/elements/Icons/Icon'
import clsx from 'clsx'

type WishlistButtonProps = {
  session: CostumeSession | null
  queryClient: ReturnType<typeof useQueryClient>
  magentoClient: ReturnType<typeof createMagentoClient>
  lightTheme?: boolean
}

export const WishlistButton = ({
  session,
  queryClient,
  magentoClient,
  lightTheme,
}: WishlistButtonProps) => {
  const [guestWishlistSku] = useLocalStorage<string[]>('guestWishlistSku', [])
  const [cartId, setCartId] = useLocalStorage<string>(CART_ID, '')

  const logout = async () => {
    setCartId('')
    await queryClient.invalidateQueries()
    await signOut({ callbackUrl: '/' })
  }
  const { data, error } = useGetWishlistIdQuery(magentoClient, {}, { enabled: !!session })

  // Make sure that the user is authenticated before showing the wishlist icon
  if (error) {
    logout()
  }

  const calcAmount = () => {
    if (!!session) {
      if (!data) return null

      return data?.customer?.wishlists?.[0]?.items_v2?.items?.length || 0
    } else {
      return guestWishlistSku?.length || 0
    }
  }
  const amount = calcAmount()

  const iconVariant = lightTheme ? 'text-sg-white' : 'text-sg-black'

  return (
    <div className={clsx('relative', iconVariant)}>
      <Icon name="bookmark" className="h-5 w-5 lgx:h-[25px] lgx:w-[25px]" viewBox="0 0 20 20" />
      <ClientOnly>
        {!!amount && (
          <span
            className={clsx(
              'absolute -top-2.5',
              amount > 9 ? '-right-2.5' : '-right-1 lgx:-right-1.5',
            )}
          >
            {amount > 9 ? '9+' : amount}
          </span>
        )}
      </ClientOnly>
    </div>
  )
}
