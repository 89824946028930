import { useCallback, useRef, memo, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import InputSearch from './components/InputSearch'
import { useNavigation } from '~/lib/Context/NavigationProvider'

type SearchProps = {
  className?: string
  lightTheme: boolean
  value: string
  onBlur?: () => void
  onChange: (value: string) => void
  onClose: () => void
  onFocus: () => void
}

// TODO: Check if memo is needed

const Search = memo(
  ({ className, lightTheme, onFocus, onBlur, value, onChange, onClose }: SearchProps) => {
    const { t } = useTranslation()
    const router = useRouter()
    const inputSearchRef = useRef<HTMLInputElement>(null)
    const { isSearchFlyoutOpen } = useNavigation()

    useEffect(() => {
      if (!isSearchFlyoutOpen && inputSearchRef.current) {
        inputSearchRef.current.blur()
      }
    }, [isSearchFlyoutOpen])

    const handleSubmit = useCallback(
      async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (value) {
          await router.push(`/search?q=${value}`, undefined, {
            scroll: true,
          })

          onClose()
        }
      },
      [value, router, onClose],
    )

    const handleChange = useCallback(
      (newValue: string) => {
        onChange(newValue)
      },
      [onChange],
    )

    const handleFocus = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        e.preventDefault()
        e.stopPropagation()
        onFocus()
      },
      [onFocus],
    )

    const handleBlur = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        e.preventDefault()
        e.stopPropagation()
        if (onBlur) onBlur()
      },
      [onBlur],
    )

    return (
      <form className={className} onSubmit={handleSubmit} data-cnstrc-search-form>
        <InputSearch
          ref={inputSearchRef}
          lightTheme={lightTheme}
          placeholder={t('Search')}
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
        />
      </form>
    )
  },
)

Search.displayName = 'Search'

export default Search
