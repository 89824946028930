import clsx from 'clsx'
import { forwardRef, useCallback, memo, useId } from 'react'
import { useTranslation } from 'next-i18next'
import { Icon } from '~/elements/Icons/Icon'

interface InputSearchProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  label?: string
  lightTheme: boolean
  value: string
  onChange: (value: string) => void
}

const InputSearch = forwardRef<HTMLInputElement, InputSearchProps>(
  ({ label, lightTheme = false, value, onChange, ...props }, ref) => {
    const { t } = useTranslation()

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value)
      },
      [onChange],
    )

    const handleFocus = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        console.log('InputSearch: handleFocus called')
        e.preventDefault()
        e.stopPropagation()
        props.onFocus?.(e)
      },
      [props],
    )

    const handleBlur = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        e.preventDefault()
        e.stopPropagation()
        props.onBlur?.(e)
      },
      [props],
    )

    const iconVariant = lightTheme ? 'text-sg-white' : 'text-sg-black'
    const inputId = useId()

    return (
      <label htmlFor={inputId} className="relative flex">
        <input
          ref={ref}
          id={inputId}
          aria-label={label || t('Search')}
          className={clsx(
            'h-[46px] w-full rounded-[100px] border-none px-5 py-3 outline-none backdrop-blur-[30px] transition-[background-color,backdrop-filter] duration-300 ease-in-out will-change-auto focus:ring-transparent lgx:h-10',
            {
              'bg-sg-black bg-opacity-10 placeholder:text-sg-black': !lightTheme,
              'bg-sg-white bg-opacity-30 placeholder:text-sg-white': lightTheme,
            },
          )}
          type="text"
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          data-cnstrc-search-input
          {...props}
        />
        <span className="sr-only">{label || t('Search')}</span>
        {label && (
          <span
            className="pointer-events-none absolute top-1/2 left-5 -translate-y-1/2 bg-transparent transition-all"
            aria-hidden="true"
          >
            {label}
          </span>
        )}
        <button
          type="submit"
          data-id="search-submit"
          className={clsx('absolute top-1/2 right-0 -translate-y-1/2 p-5', iconVariant)}
          title={t('Search')}
          aria-label={t('Search')}
          data-cnstrc-search-submit-btn
        >
          <Icon name="search-icon" className="h-4 w-4" />
        </button>
      </label>
    )
  },
)

// TODO: Check if memo is needed

InputSearch.displayName = 'InputSearch'

export default memo(InputSearch)
