import { NavigationElement, NavCategoryWithElements } from '@contentfulTypes'
import { Icon } from '~/elements/Icons/Icon'
import { Link } from '~/elements/Link/Link'
import { useTranslation } from 'next-i18next'

export const MobileFlyoutSubMenu: React.FC<{
  category: NavCategoryWithElements
  onBack: () => void
  trackNavItemClick: (item: NavigationElement | NavCategoryWithElements, level: string) => void
}> = ({ category, onBack, trackNavItemClick }) => {
  const { t } = useTranslation()

  return (
    <div>
      <button
        onClick={() => {
          onBack()
          trackNavItemClick(category, '2rd_level')
        }}
        className="mb-[30px] flex items-center gap-2"
      >
        <Icon name="chevron-left" />
        <span className="text-20-25-sg font-extralight">{t('all')}</span>
      </button>
      <Link
        href={category.pageContent?.slug || '#'}
        className="mb-[15px] block w-full border-b pb-2.5 text-20-25-sg font-extralight text-sg-black"
      >
        {category.title}
      </Link>
      {category.subElementsCollection?.items.map(
        (item) =>
          item && (
            <Link
              key={item.sys.id}
              href={item.pageContent?.slug || '#'}
              className="mb-2.5 flex w-full items-center justify-between border-b border-sg-light-grey pb-2.5 text-15-22-sg font-extralight text-sg-black first-letter:uppercase"
              onClick={() => trackNavItemClick(item, '3rd_level')}
            >
              {item.title}
            </Link>
          ),
      )}
    </div>
  )
}
