import { useCallback, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { DesktopHeader } from './DesktopNavigation/DesktopHeader'
import { MobileHeader } from './MobileNavigation/MobileHeader'
import { Media } from '~/lib/Media'
import { useNavigation } from '~/lib/Context/NavigationProvider'
import { DEFAULT_LOCALE } from 'config/constants'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { useBanderoleQuery } from '@contentfulTypes'
import { BottomBanderole } from '../Banderole/components/BottomBanderole'
import { MobileBanderole } from '../Banderole/Mobile/MobileBanderole'
import { DesktopBanderole } from '../Banderole/Desktop/DesktopBanderole'
import useLocalStorage from '~/hooks/useLocalStorage'

type HeaderProps = {}

export const Header = ({}: HeaderProps) => {
  const router = useRouter()
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const { data } = useBanderoleQuery(contentfulClient, {
    preview,
    locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
  })

  const [isBanderoleClosed, setIsBanderoleClosed] = useLocalStorage<boolean>(
    'newsletterBanderoleClosed',
    false,
  )
  const [mounted, setMounted] = useState(false)

  const {
    isFlyoutOpen,
    closeHeaderFlyout,
    isSearchFlyoutOpen,
    isDesktopFlyoutOpen,
    isHeaderHidden,
    isHeaderSticky,
    setHeaderHeight,
  } = useNavigation()

  const headerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight)
    }
  }, [setHeaderHeight])

  const handleBanderoleClose = useCallback(() => {
    setIsBanderoleClosed(true)
  }, [setIsBanderoleClosed])

  useEffect(() => {
    setMounted(true)
  }, [])

  const hideBottomBanderole = mounted ? isBanderoleClosed : true

  const headerClasses = clsx(
    'z-40 transition-all duration-[1000ms] top-2.5 lgx:top-5 left-1/2 -translate-x-1/2 w-full max-w-[1454px] lgx:px-5',
    isHeaderSticky ? 'fixed' : 'absolute',
    isHeaderHidden ? '-translate-y-[200px]' : 'translate-y-0',
  )

  const topBanderoleClasses = clsx(
    'transition-all duration-500 ease-in-out overflow-hidden',
    isHeaderSticky && 'hidden',
  )

  const bottomBanderoleClasses = clsx(
    'transition-all duration-500 ease-in-out overflow-hidden px-2.5 lgx:px-0',
    (!isHeaderSticky || isDesktopFlyoutOpen || isSearchFlyoutOpen) && 'hidden',
  )

  return (
    <>
      <div
        className={clsx(
          'bg-[linear-gradient(to_bottom,white_0%,rgba(255,255,255,0.5)_20%,rgba(255,255,255,0.3)_40%,rgba(255,255,255, 0.01)_70%,transparent_100%)] fixed left-0 right-0 z-40 h-[80px] backdrop-blur-[20px] transition-all duration-[1000ms] lgx:hidden',
          isSearchFlyoutOpen && 'hidden',
          isHeaderHidden || !isHeaderSticky ? '-translate-y-[200px]' : 'translate-y-0',
        )}
      />
      <header ref={headerRef} className={headerClasses} onMouseLeave={closeHeaderFlyout}>
        <Media
          greaterThanOrEqual="lgx"
          className={clsx(isHeaderSticky && 'shadow-[0_10px_20px_0_rgba(0,0,0,0.1)]')}
        >
          <DesktopBanderole banderoleData={data} className={clsx('mb-2.5', topBanderoleClasses)} />
          <DesktopHeader isBanderoleClosed={hideBottomBanderole} />
        </Media>
        <Media lessThan="lgx" className="px-2.5">
          <MobileBanderole banderoleData={data} className={clsx('mb-2.5', topBanderoleClasses)} />
          <MobileHeader isBanderoleClosed={hideBottomBanderole} />
        </Media>
        <div className={clsx(bottomBanderoleClasses)}>
          <BottomBanderole
            banderoleData={data}
            isBanderoleClosed={hideBottomBanderole}
            handleBanderoleClose={handleBanderoleClose}
          />
        </div>
      </header>

      {isFlyoutOpen && (
        <div
          className="fixed inset-0 z-30 bg-sg-black bg-opacity-5 backdrop-blur-[5px]"
          onClick={closeHeaderFlyout}
        />
      )}
    </>
  )
}
export default Header
