import { NavigationElementWithSubElements } from '@contentfulTypes'
import clsx from 'clsx'
import { useNavigation } from '~/lib/Context/NavigationProvider'
import { MenuNavItem } from './MenuNavItem'
import { useGTM, DataLayerEnum } from '~/hooks/useGTM'
import { useCallback } from 'react'

interface FirstLevelMenuProps {
  className?: string
  navData?: NavigationElementWithSubElements[]
}

export const FirstLevelMenu = ({ className, navData }: FirstLevelMenuProps) => {
  const { toggleDesktopFlyout, displayLightTheme } = useNavigation()
  const { dataLayer } = useGTM()

  const handleNavItemClick = (itemTitle: string) => {
    dataLayer(DataLayerEnum.EVENT, 'nav_item_click', {
      method: 'navigation',
      navigation_level: '1st_level',
      navigation_item: itemTitle,
    })
  }

  const handleMouseEnter = useCallback(
    (hasSubElements: boolean, index: number) => {
      requestAnimationFrame(() => {
        if (hasSubElements) {
          toggleDesktopFlyout('first', index)
        } else {
          toggleDesktopFlyout('first', -1)
        }
      })
    },
    [toggleDesktopFlyout],
  )

  if (!navData || !navData.length) return null

  return (
    <nav className={className}>
      <menu className="flex items-center gap-[30px]">
        {navData.map((item, index) => {
          const hasSubElements = (item.subElementsCollection?.items?.length ?? 0) > 0

          return (
            <li
              key={item.sys?.id}
              className={clsx('relative', {
                'block rounded-sm bg-sg-neon-yellow py-0.5 px-[5px]':
                  item.highlightedColor === 'Yellow',
              })}
              onMouseEnter={() => handleMouseEnter(hasSubElements, index)}
            >
              <MenuNavItem
                item={item}
                lightTheme={displayLightTheme}
                onClick={() => handleNavItemClick(item.title!)}
              />
            </li>
          )
        })}
      </menu>
    </nav>
  )
}
