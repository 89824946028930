import { useMemo } from 'react'
import { BanderoleQuery } from '@contentfulTypes'
import clsx from 'clsx'
import useCountdownData from '~/hooks/useCountdownData'
import BanderoleCountdown from '../components/BanderoleCountdown'
import { BanderoleDiscountCodeButton } from '../components/BanderoleDiscountCodeButton'
import { MobileBanderoleText } from './MobileBanderoleText'

type MobileBanderoleProps = {
  banderoleData?: BanderoleQuery
  className?: string
  onClick?: () => void
}

export const MobileBanderole = ({ banderoleData, className }: MobileBanderoleProps) => {
  const banderoleItem = useMemo(
    () => banderoleData?.pageCollection?.items[0]?.banderole,
    [banderoleData],
  )
  const countdownData = useCountdownData({
    isBanderoleVisible:
      banderoleItem?.countDown?.countDownDate && !banderoleItem?.countDown?.hideCountDown,
    banderoleData: banderoleItem?.countDown?.countDownDate,
    rerenderCondition: banderoleData?.pageCollection?.items,
  })
  const banderoleTextData = {
    newsletterText: banderoleItem?.countDown?.newsletterText,
    promoText: banderoleItem?.countDown?.promoText,
    disableNewsletterPopup: banderoleItem?.countDown?.disableNewsletterPopup,
    fullClick: banderoleItem?.countDown?.fullClick,
  }
  const isCountdown = banderoleItem?.countDown
  const isDiscountCode = isCountdown?.discountCode
  const deliverDays = banderoleData?.pageCollection?.items[0]?.deliveryDays

  return (
    <>
      <div
        className={clsx(
          'relative box-border grid gap-3 rounded-[5px] px-2.5 print:hidden',
          isDiscountCode ? 'grid-cols-[1fr_auto]' : 'justify-center',
          countdownData.isReady ? 'py-2' : 'py-1',
          className,
        )}
        style={{
          background: banderoleItem?.backgroundColor as string,
          color: banderoleItem?.textColor as string,
        }}
      >
        <div>
          {isCountdown && (
            <MobileBanderoleText deliveryDays={deliverDays} banderoleData={banderoleTextData} />
          )}
        </div>
        {isDiscountCode && (
          <BanderoleDiscountCodeButton
            className="h-full"
            small={!countdownData.isReady}
            color={banderoleItem?.textColor as string}
            discountCode={banderoleItem?.countDown?.discountCode as string}
          >
            {countdownData.isReady ? (
              <BanderoleCountdown className="mt-1.5 leading-[15px]" countdownData={countdownData} />
            ) : undefined}
          </BanderoleDiscountCodeButton>
        )}
      </div>
    </>
  )
}
