import { Icon } from '~/elements/Icons/Icon'

type UserButtonProps = {
  lightTheme?: boolean
}

export const UserButton = ({ lightTheme }: UserButtonProps) => {
  const iconVariant = lightTheme ? 'text-sg-white' : 'text-sg-black'

  return (
    <div className={iconVariant}>
      <Icon name="user" className="h-5 w-5 lgx:h-[25px] lgx:w-[25px]" viewBox="0 0 20 20" />
    </div>
  )
}
