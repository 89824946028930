import { useMemo } from 'react'
import clsx from 'clsx'
import { Logo } from '../components/Logo'
import Search from '../../Search/Search'
import { MobileFlyout } from './components/MobileFlyout'
import { CioSearchMobileFlyout } from '~/modules/Search/components/SearchMobileFlyout'
import { useNavigation } from '~/lib/Context/NavigationProvider'
import { useMobileHeaderQuery, NavigationElementWithSubElements } from '@contentfulTypes'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { useRouter } from 'next/router'
import { DEFAULT_LOCALE } from '~/config/constants'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { Icon } from '~/elements/Icons/Icon'
import { useAutocomplete } from '~/lib/Context/AutocompleteProvider'
import { HeaderButtonBar } from '../components/HeaderButtonBar'

type MobileHeaderProps = {
  className?: string
  isBanderoleClosed?: boolean
}

export const MobileHeader = ({ className, isBanderoleClosed }: MobileHeaderProps) => {
  const { searchValue, setQuery } = useAutocomplete()
  const {
    displaySearchInput,
    handleDisplaySearchInput,
    isSearchFlyoutOpen,
    handleSearchFocus,
    handleSearchClose,
    displayLightTheme,
    toggleMobileFlyout,
    isMobileFlyoutOpen,
    isHeaderSticky,
  } = useNavigation()

  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data } = useMobileHeaderQuery(contentfulClient, {
    preview,
    locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
  })

  const mobileNavData = useMemo(() => {
    return (
      (data?.pageCollection?.items?.[0]?.mainMegaNavigation?.mobileFirstLevelItemsCollection
        ?.items as NavigationElementWithSubElements[]) || []
    )
  }, [data])

  const iconVariant = displayLightTheme ? 'text-sg-white' : 'text-sg-black'

  return (
    <nav
      className={clsx(
        'relative grid-rows-2 rounded-[5px] py-5 px-2.5 transition-all duration-300 ease-in-out will-change-auto',
        {
          'bg-sg-white': isHeaderSticky || isSearchFlyoutOpen,
          'rounded-b-none': isSearchFlyoutOpen || !isBanderoleClosed,
        },
        className,
      )}
    >
      <div
        className={clsx(
          'relative z-10 grid grid-cols-[1fr_auto_1fr] transition-[margin-bottom] duration-300 ease-in-out',
        )}
      >
        {mobileNavData && mobileNavData.length && (
          <div className="flex items-center">
            <button
              className={clsx('-m-4 p-4', iconVariant)}
              onClick={() => toggleMobileFlyout(true)}
            >
              <Icon name="hamburger" className="h-2.5 w-[26px]" />
            </button>
            <MobileFlyout
              isMobileFlyoutOpen={isMobileFlyoutOpen}
              mobileNavData={mobileNavData}
              handleClose={() => toggleMobileFlyout(false)}
            />
          </div>
        )}
        <Logo lightTheme={displayLightTheme} className="h-5 w-20" />
        <HeaderButtonBar
          displayWishlistIcon={!isHeaderSticky}
          lightTheme={displayLightTheme}
          className="justify-end"
          onDisplaySearchInput={handleDisplaySearchInput}
        />
      </div>
      <div
        className={clsx('rounded-[5px] transition-all duration-300 ease-in-out', {
          hidden: !displaySearchInput,
          'mt-5': !isSearchFlyoutOpen,
          'absolute top-0 left-0 right-0 z-20 bg-sg-white px-2.5 py-5': isSearchFlyoutOpen,
        })}
      >
        <div className="relative">
          <Search
            lightTheme={displayLightTheme}
            value={searchValue}
            onFocus={handleSearchFocus}
            onClose={handleSearchClose}
            onChange={setQuery}
          />
          <div
            className={clsx(
              'absolute -left-2.5 -right-2.5 top-full z-30 transition-all duration-300 ease-in-out',
              isSearchFlyoutOpen
                ? 'translate-y-0 opacity-100'
                : 'pointer-events-none -translate-y-4 opacity-0',
            )}
          >
            <CioSearchMobileFlyout onClose={handleSearchClose} />
          </div>
        </div>
      </div>
    </nav>
  )
}
