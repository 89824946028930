import { useTranslation } from 'next-i18next'
import { HeaderButtonBarItem } from './HeaderButtonBarItem'
import { WishlistButton } from './WishlistButton'
import { CartButton } from './CartButton'
import { UserButton } from './UserButton'
import { useSharedHeaderButtonsLogic } from '../hooks/useSharedHeaderButtonsLogic'
import { ClientOnly } from '~/elements/ClientOnly/ClientOnly'
import { Icon } from '~/elements/Icons/Icon'

type HeaderButtonBarProps = {
  className?: string
  displayWishlistIcon?: boolean
  lightTheme?: boolean
  onDisplaySearchInput?: (display: boolean) => void
}

export const HeaderButtonBar = ({
  className,
  displayWishlistIcon = true,
  lightTheme,
  onDisplaySearchInput,
}: HeaderButtonBarProps) => {
  const { t } = useTranslation()
  const { session, queryClient, magentoClient } = useSharedHeaderButtonsLogic()

  const iconVariant = lightTheme ? 'text-sg-white' : 'text-sg-black'

  return (
    <ul className={`flex flex-nowrap items-center gap-x-2.5 ${className}`}>
      {displayWishlistIcon && (
        <HeaderButtonBarItem href="/wishlist" title={t('Wishlist')}>
          <WishlistButton
            lightTheme={lightTheme}
            session={session}
            queryClient={queryClient}
            magentoClient={magentoClient}
          />
        </HeaderButtonBarItem>
      )}
      {!displayWishlistIcon && (
        <li>
          <button
            type="button"
            className={`${iconVariant} -m-4 flex p-4`}
            onClick={() => onDisplaySearchInput?.(true)}
            title={t('Search')}
          >
            <Icon name="search-icon" className="h-4 w-4" />
          </button>
        </li>
      )}
      <ClientOnly>
        <HeaderButtonBarItem
          href={session ? '/account' : '/login'}
          title={session ? t('account') : t('Login')}
        >
          <UserButton lightTheme={lightTheme} />
        </HeaderButtonBarItem>
      </ClientOnly>
      <HeaderButtonBarItem href="/cart" title={t('Cart')}>
        <CartButton lightTheme={lightTheme} magentoClient={magentoClient} />
      </HeaderButtonBarItem>
    </ul>
  )
}
