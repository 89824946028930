import { BanderoleQuery } from '@contentfulTypes'
import { DesktopBanderoleContent } from './DesktopBanderoleContent'
import { DesktopBanderoleInfo } from './DesktopBanderoleInfo'
import clsx from 'clsx'

type DesktopBanderoleProps = {
  banderoleData?: BanderoleQuery
  className?: string
}

export const DesktopBanderole = ({ banderoleData, className }: DesktopBanderoleProps) => {
  return (
    <div
      className={clsx(
        "bg-text-white relative grid h-10 w-full grid-cols-[1fr_3fr_1fr] rounded-[5px] bg-sg-white [grid-template-areas:'._text_buttons'] print:hidden",
        className,
      )}
      style={{
        background: banderoleData?.pageCollection?.items[0]?.banderole?.backgroundColor as string,
        color: banderoleData?.pageCollection?.items[0]?.banderole?.textColor as string,
      }}
    >
      <DesktopBanderoleContent className="[grid-area:text]" banderoleData={banderoleData} />
      <DesktopBanderoleInfo className="[grid-area:buttons]" />
    </div>
  )
}
