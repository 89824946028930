import { NewsletterDisclaimer } from '@contentfulTypes'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { Icon } from '~/elements/Icons/Icon'
import { NewsletterContent } from '../Newsletter/components/NewsletterContent'
import { useNewsletterData } from '../Newsletter/hooks/useNewsletterData'

type BanderoleNewsletterProps = {
  open?: boolean
  close?(event: React.MouseEvent | React.KeyboardEvent): void
}

export const BanderoleNewsletter = ({ open, close }: BanderoleNewsletterProps) => {
  const { headline, benefits, imageDesktop, imageMobile, disclaimer } = useNewsletterData()

  return (
    <DialogOverlay
      className="z-50 overflow-hidden p-5 md:py-[10vh]"
      isOpen={open}
      onDismiss={close}
    >
      <DialogContent
        className="relative mx-auto flex max-h-full w-full max-w-[1414px] flex-col overflow-y-auto lg:flex-row"
        style={{
          marginTop: 0,
          marginBottom: 0,
          padding: 0,
        }}
      >
        <button
          className="absolute top-2.5 right-2.5 z-50 grid h-6 w-6 items-center justify-center text-sg-white lg:text-sg-black"
          onClick={close}
          aria-label="Close Newsletter Popup"
        >
          <Icon name="cross" className="h-6 w-6" />
        </button>
        <NewsletterContent
          headline={headline}
          imageDesktop={imageDesktop}
          imageMobile={imageMobile}
          disclaimer={disclaimer as NewsletterDisclaimer}
          subscriptionBenefits={benefits as string[]}
          isBanderole
        />
      </DialogContent>
    </DialogOverlay>
  )
}
