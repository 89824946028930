import { Image } from '~/elements/Image/Image'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import { SearchResultSuggestions } from './SearchResultSuggestions'
import { SearchResultProductList } from './SearchResultProductList'
import { useAutocomplete } from '~/lib/Context/AutocompleteProvider'
import { useRouter } from 'next/router'
import { SearchNotFoundResults } from './SearchNotFoundResults'
import { ButtonMain } from '~/elements/Button/ButtonMain'

type SearchMobileFlyoutProps = {
  onClose: () => void
}

export const CioSearchMobileFlyout = ({ onClose }: SearchMobileFlyoutProps) => {
  const { searchSuggestions, products, searchValue, loading } = useAutocomplete()

  const { t } = useTranslation()
  const router = useRouter()
  const [isEmptySearch, setIsEmptySearch] = useState(false)
  useEffect(() => {
    setIsEmptySearch(
      products.length === 0 &&
        searchSuggestions.length === 0 &&
        searchValue.trim().length !== 0 &&
        !loading,
    )
  }, [products, searchSuggestions, searchValue, loading])

  const handleSuggestionClick = async (suggestion: string) => {
    await router.push(`/search?q=${suggestion}`, undefined, {
      scroll: true,
    })
  }

  if (!searchValue || searchValue.trim().length === 0) {
    return null
  }

  return (
    <div
      className={clsx(
        'relative max-h-[calc(100vh-(var(--header-padding)))] w-full overflow-y-scroll rounded-b-[5px] bg-primary-white px-4 pt-7.5 pb-7.5 transition-all duration-1000 ease-in-out',
      )}
      data-cnstrc-autosuggest
    >
      <div className="flex items-center justify-end">
        <button onClick={onClose} className="h-6 w-6">
          <Image
            src="/images/Icons/close.svg"
            width={24}
            height={24}
            placeholder="empty"
            alt="close-icon"
          />
        </button>
      </div>
      {isEmptySearch && <SearchNotFoundResults searchValue={searchValue} />}
      <div className="mt-7.5 grid gap-7.5 overflow-auto">
        <SearchResultSuggestions
          suggestions={searchSuggestions}
          handleSuggestionClick={handleSuggestionClick}
        />
        <ButtonMain className="rounded-md" onClick={() => handleSuggestionClick(searchValue)}>
          <span className="text-15-22-sg font-medium">{t('Show all results')}</span>
        </ButtonMain>
        <SearchResultProductList products={products} />
      </div>
    </div>
  )
}
