import clsx from 'clsx'
import parse from 'html-react-parser'
import { Icon } from '~/elements/Icons/Icon'

type BanderoleTextWithIconProps = {
  text: string
  arrowStyles?: string
}
export const BanderoleTextWithIcon = ({ text, arrowStyles }: BanderoleTextWithIconProps) => {
  return (
    <>
      {parse(text)}
      <span className={clsx('inline-flex', arrowStyles)}>
        <Icon name="arrow-right" className="ml-1.5 h-[16px] w-[16px] lg:ml-2.5" />
      </span>
    </>
  )
}
