import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { createMagentoClient } from '~/graphql/magentoClient'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { DEFAULT_LOCALE } from '~/config/constants'
import { useSession } from '~/hooks/useSession'

export const useSharedHeaderButtonsLogic = () => {
  const [session] = useSession()
  const router = useRouter()
  const queryClient = useQueryClient()

  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const magentoClient = createMagentoClient({
    usePost: false,
    queryClient,
    locale: finalLocale,
  })

  return {
    session,
    queryClient,
    magentoClient,
  }
}
