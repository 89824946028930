import clsx from 'clsx'
import { useTranslation } from 'next-i18next'

type BanderoleCountDown = {
  days: string
  hours: string
  minutes: string
  seconds: string
}

type BanderoleCountdownProps = {
  countdownData: BanderoleCountDown
  isDesktop?: boolean
  className?: string
  showLabel?: boolean
}

type SingleDataBoxProps = {
  number?: string
  isDesktop?: boolean
  label: string
  showLabel?: boolean
}

const SingleDataBox = ({ number, isDesktop, label, showLabel }: SingleDataBoxProps) => {
  return (
    <div className={clsx('flex items-center text-center', isDesktop && 'lgx:py-1')}>
      <div
        className={clsx(
          'min-w-[20px] text-[15px] font-bold [font-synthesis:none]',
          isDesktop ? 'lgx:text-p' : 'leading-none',
        )}
      >
        {number}
      </div>
      <div
        className={clsx(
          'mt-px text-[12px] font-extralight leading-normal lg:block lgx:mt-0',
          !showLabel && 'hidden lg:block',
        )}
      >
        {label}
      </div>
    </div>
  )
}

const Colon = () => {
  return <div className="mx-1 text-center text-[15px] font-bold">:</div>
}

const BanderoleCountdown = ({
  countdownData,
  isDesktop,
  className,
  showLabel = false,
}: BanderoleCountdownProps) => {
  const { t } = useTranslation()
  return (
    <div className={clsx('flex max-w-fit items-center justify-center', className && className)}>
      <SingleDataBox
        number={countdownData?.days}
        isDesktop={isDesktop}
        showLabel={showLabel}
        label={t('Days')}
      />
      <Colon />
      <SingleDataBox
        number={countdownData?.hours}
        isDesktop={isDesktop}
        showLabel={showLabel}
        label={t('Hours')}
      />
      <Colon />
      <SingleDataBox
        number={countdownData?.minutes}
        isDesktop={isDesktop}
        showLabel={showLabel}
        label={t('Minutes')}
      />
      <Colon />
      <SingleDataBox
        number={countdownData?.seconds}
        isDesktop={isDesktop}
        showLabel={showLabel}
        label={t('Seconds')}
      />
    </div>
  )
}

export default BanderoleCountdown
