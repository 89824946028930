import clsx from 'clsx'
import { BanderoleQuery } from '@contentfulTypes'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { Icon } from '~/elements/Icons/Icon'
import { BanderoleNewsletter } from '~/modules/BanderoleNewsletter/BanderoleNewsletter'
import { ClientOnly } from '~/elements/ClientOnly/ClientOnly'

type BottomBanderoleProps = {
  banderoleData?: BanderoleQuery
  className?: string
  isBanderoleClosed?: boolean
  handleBanderoleClose?: () => void
}

export const BottomBanderole = ({
  banderoleData,
  className,
  isBanderoleClosed,
  handleBanderoleClose,
}: BottomBanderoleProps) => {
  const banderole = banderoleData?.pageCollection?.items?.[0]?.secondaryBanderole
  const { t } = useTranslation()
  const [showNewsletterPopup, setShowNewsletterPopup] = useState(false)

  if (isBanderoleClosed) return null

  return (
    <ClientOnly>
      <div className={clsx('min-h-10 relative', className)}>
        <div
          className="sticky flex h-full w-full items-center rounded-b-[5px] py-[8px] px-3.5 font-extralight lg:grid lg:grid-cols-[1fr_3fr_1fr] lg:[grid-template-areas:'._text_actions']"
          style={{
            background: banderole?.backgroundColor as string,
            color: banderole?.textColor as string,
          }}
        >
          <div className="flex w-full items-center justify-center gap-2 [grid-area:text] lg:gap-[50px]">
            <div
              className="absolute h-full w-full cursor-pointer"
              onClick={() => setShowNewsletterPopup(true)}
            ></div>
            <div className="flex w-full items-center gap-2.5 lg:w-fit">
              <Icon name="newsletter-promo" />
              <span
                className="w-full text-[15px] leading-[22px]"
                dangerouslySetInnerHTML={{
                  __html: banderole?.banderoleContent?.newsletterText as string,
                }}
              />
            </div>
            <span className="cursor-pointer whitespace-nowrap text-[15px] font-medium leading-[15px] underline">
              {t('Register now')}
            </span>
          </div>
          <div
            onClick={handleBanderoleClose}
            className="ml-2.5 place-self-end self-center [grid-area:actions] lg:ml-0"
          >
            <Icon
              className="relative z-10 h-[14px] w-[14px] cursor-pointer lg:h-[24px] lg:w-[24px]"
              name="cross"
            />
          </div>
        </div>

        <BanderoleNewsletter
          open={showNewsletterPopup}
          close={() => setShowNewsletterPopup(false)}
        />
      </div>
    </ClientOnly>
  )
}
