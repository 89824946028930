import clsx from 'clsx'
import { useId } from 'react'
import { RteRteLinks } from '@contentfulTypes'
import { useSubscribeEmailToNewsletterMutation } from '@magentoTypes'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { DEFAULT_LOCALE, EMAIL_PATTERN } from '~/config/constants'
import { ButtonMain } from '~/elements/Button/ButtonMain'
import { InputMain } from '~/elements/Input/InputMain'
import { createMagentoClient } from '~/graphql/magentoClient'
import { RTEParser } from '~/lib/RTEParser/RTEParser'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { parseMagentoGraphqlError } from '~/lib/parseMagentoGraphlError'

type FormData = {
  email: string
}
type NewsletterFormProps = {
  disclaimer?: any
  disclaimerRte?: any
  className?: string
  handleSubmitTracking: (email: string) => void
}
export const NewsletterForm = ({
  disclaimer,
  className,
  handleSubmitTracking,
}: NewsletterFormProps) => {
  const [subscribeSuccessful, setSubscribeSuccessful] = useState(false)
  const [currentEmail, setCurrentEmail] = useState('')
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const router = useRouter()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const mutationClient = createMagentoClient({ queryClient, usePost: true, locale: finalLocale })
  const newsletterMutation = useSubscribeEmailToNewsletterMutation(mutationClient, {
    onSuccess: async (data) => {
      toast.success(
        t('You are almost there! Please confirm your subscription in the email we just sent to'),
      )
      setSubscribeSuccessful(true)
    },
    onError: async (error) => {
      const parsedError = parseMagentoGraphqlError(error)
      if (parsedError?.extensions?.category.includes('already-exists')) {
        toast.error(t('This email address is already subscribed'))
      } else if (error.message.includes('Guests can not subscribe')) {
        toast.error(
          t('Guests can not subscribe to the newsletter. Please create an account to subscribe.'),
        )
      } else if (error.message.includes('account sign-in was incorrect')) {
        toast.error(
          t(
            'Your account is pending confirmation or disabled temporarily. Please try again later.',
          ),
        )
      } else {
        toast.error(t('Something went wrong. Please try again later.'))
      }
      setSubscribeSuccessful(false)
    },
  })
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      email: '',
    },
  })

  const onSubmit = handleSubmit(async ({ email }) => {
    newsletterMutation.mutate({ email })
    handleSubmitTracking(email)
    setCurrentEmail(email)
    reset()
  })

  const id = useId()
  const newsletterId = `newsletter-email-${id}`

  return (
    <>
      <form onSubmit={onSubmit} className={clsx('relative', className)}>
        <label htmlFor={newsletterId} className="sr-only">
          {t('E-Mail Address')}
        </label>
        <InputMain
          id={newsletterId}
          name="email"
          type="text"
          placeholder={`${t('E-Mail Address')}`}
          autoComplete="email"
          dark
          rules={{
            required: {
              value: true,
              message: t('{{val}} is required', { val: t('E-Mail') }),
            },
            pattern: {
              value: EMAIL_PATTERN,
              message: t('Please enter a valid email'),
            },
          }}
          register={register}
          errors={errors}
          aria-required="true"
          aria-invalid={!!errors.email}
          aria-describedby={errors.email ? `${newsletterId}-error` : undefined}
        />
        {subscribeSuccessful ? (
          <p className="mb-2.5">
            {`${t(
              'You are almost there! Please confirm your subscription in the email we just sent to',
            )} ${currentEmail}.`}
          </p>
        ) : null}
        <ButtonMain fullWidth type="submit">
          {t('Subscribe')}
        </ButtonMain>
      </form>
      {disclaimer ? (
        <RTEParser
          className="font-extralight text-sg-black [&_p]:text-10-16-sg lg:[&_p]:text-12-20-sg"
          rteJSONContent={disclaimer?.json}
          links={disclaimer?.links as RteRteLinks}
        />
      ) : null}
    </>
  )
}
