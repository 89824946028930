import { useTranslation } from 'next-i18next'
import { useMemo, useState } from 'react'
import { BanderoleQuery } from '@contentfulTypes'
import useCountdownData from '~/hooks/useCountdownData'
import BanderoleCountdown from '../components/BanderoleCountdown'
import { BanderoleDiscountCodeButton } from '../components/BanderoleDiscountCodeButton'
import clsx from 'clsx'
import { DesktopBanderoleNewsletterText } from './DesktopBanderoleNewsletterText'

type DesktopBanderoleContentProps = {
  banderoleData?: BanderoleQuery
  className: string
}

export const DesktopBanderoleContent = ({
  banderoleData,
  className,
}: DesktopBanderoleContentProps) => {
  const { t } = useTranslation()
  const data = banderoleData?.pageCollection?.items[0]?.banderole?.countDown
  const deliveryDays = banderoleData?.pageCollection?.items[0]?.deliveryDays
  const textColor = banderoleData?.pageCollection?.items[0]?.banderole?.textColor
  const mappedData = useMemo(
    () => ({
      fullClick: data?.fullClick,
      promoText: data?.promoText,
      newsletterText: data?.newsletterText,
      disableNewsletterPopup: data?.disableNewsletterPopup,
    }),
    [data],
  )
  const countdownData = useCountdownData({
    isBanderoleVisible: data?.countDownDate && !data?.hideCountDown,
    banderoleData: data?.countDownDate,
    rerenderCondition: banderoleData?.pageCollection?.items,
  })

  return (
    <div
      className={clsx(
        'flex w-full items-center justify-center gap-[50px] whitespace-nowrap',
        className,
      )}
    >
      {!!mappedData?.newsletterText ? (
        <DesktopBanderoleNewsletterText
          data={{
            ...mappedData,
            textColor,
          }}
        />
      ) : (
        <span className="mr-3 text-p">
          {t('Free Shipping', {
            days: deliveryDays,
          })}
        </span>
      )}
      {data?.discountCode && (
        <BanderoleDiscountCodeButton
          color={textColor as string}
          discountCode={data?.discountCode}
        />
      )}
      {countdownData.isReady && <BanderoleCountdown countdownData={countdownData} isDesktop />}
    </div>
  )
}
