import { Link } from 'elements/Link/Link'
import clsx from 'clsx'
import {
  NavigationElement,
  NavigationElementWithSubElements,
  NavCategoryWithElements,
} from '@contentfulTypes'
import { ColorTheme } from '~/lib/constants/themeConstants'

type MenuNavItemProps = {
  lightTheme?: boolean
  item: NavigationElement | NavigationElementWithSubElements | NavCategoryWithElements
  navigationTheme?: ColorTheme
  className?: string
  onClick?: () => void
}

export const MenuNavItem = ({ item, lightTheme, className, onClick }: MenuNavItemProps) => {
  if (!item) return null

  const hasHighlightedColor = (
    item: MenuNavItemProps['item'],
  ): item is NavigationElement | NavigationElementWithSubElements => {
    return 'highlightedColor' in item
  }

  const commonClasses = clsx(
    'flex items-center text-20-25-sg duration-75 cursor-pointer font-extralight relative after:absolute after:-bottom-[6px] hover:after:w-full after:block after:border-b hover:after:border-b-sg-black',
    {
      'text-sg-black': !lightTheme,
      'text-sg-white': lightTheme && !(hasHighlightedColor(item) && item.highlightedColor),
    },
    className,
  )

  if (item.pageContent?.slug) {
    return (
      <Link
        href={item.pageContent.slug as string}
        className={commonClasses}
        prefetch={false}
        onClick={onClick}
      >
        {item.title}
      </Link>
    )
  }

  return (
    <button className={commonClasses} onClick={onClick}>
      {item.title}
    </button>
  )
}
