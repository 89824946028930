import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { BanderoleNewsletter } from '~/modules/BanderoleNewsletter/BanderoleNewsletter'
import Link from 'next/link'
import { BanderoleTextWithIcon } from '../components/BanderoleTextWithIcon'

type MobileBanderoleProps = {
  banderoleData: {
    promoText?: string | null
    newsletterText?: string | null
    disableNewsletterPopup?: boolean | null
    fullClick?: string | null
  }
  deliveryDays?: string | null
}

export const MobileBanderoleText = ({ banderoleData, deliveryDays }: MobileBanderoleProps) => {
  const [showNewsletterPopup, setShowNewsletterPopup] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      <div className="flex h-full items-center text-[15px] font-extralight leading-[22px]">
        {banderoleData?.newsletterText ? (
          <>
            <button
              className="flex text-left"
              onClick={() => {
                if (!banderoleData?.disableNewsletterPopup && !banderoleData.fullClick)
                  setShowNewsletterPopup(true)
              }}
            >
              <div>
                {banderoleData?.newsletterText && (
                  <span className="text-[15px] leading-[22px]">
                    <BanderoleTextWithIcon
                      arrowStyles="relative -bottom-1"
                      text={banderoleData?.newsletterText}
                    />
                  </span>
                )}
                {banderoleData?.promoText && (
                  <div className="relative text-left text-[15px] leading-[22px]">
                    <BanderoleTextWithIcon
                      arrowStyles="relative -bottom-1"
                      text={banderoleData?.promoText}
                    />
                  </div>
                )}
                {banderoleData.fullClick && (
                  <Link href={banderoleData.fullClick} className="absolute inset-0" />
                )}
              </div>
            </button>
          </>
        ) : (
          t('Free Shipping', {
            days: deliveryDays,
          })
        )}
      </div>
      <BanderoleNewsletter open={showNewsletterPopup} close={() => setShowNewsletterPopup(false)} />
    </>
  )
}
