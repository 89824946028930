import React, { memo, useCallback, useMemo, useState } from 'react'
import {
  NavigationElement,
  NavigationElementWithSubElements,
  NavCategoryWithElements,
} from '@contentfulTypes'
import clsx from 'clsx'
import { HeaderButtonBar } from '../../components/HeaderButtonBar'
import { Icon } from '~/elements/Icons/Icon'
import { Link } from '~/elements/Link/Link'
import { BottomSection } from './BottomSection'
import { DataLayerEnum, useGTM } from '~/hooks/useGTM'
import { MobileFlyoutSubMenu } from './MobileFlyoutSubMenu'

type MobileFlyoutProps = {
  isMobileFlyoutOpen: boolean
  handleClose: () => void
  mobileNavData: NavigationElementWithSubElements[] | null | undefined
}

const NavItem = memo(
  ({
    item,
    itemIndex,
    trackNavItemClick,
    handleSubCategorySelect,
  }: {
    item: NavigationElementWithSubElements
    itemIndex: number
    trackNavItemClick: (item: NavigationElement | NavCategoryWithElements, level: string) => void
    handleSubCategorySelect: (category: NavCategoryWithElements) => void
  }) => {
    return (
      <div className="mb-[30px]">
        <Link
          href={item.pageContent?.slug || '#'}
          className={clsx('text-20-25-sg font-extralight text-sg-black', {
            'mb-[15px] block border-b pb-2.5': !item.highlightedColor,
            'inline-block rounded-sm bg-sg-neon-yellow py-0.5 px-[5px]':
              item.highlightedColor === 'Yellow',
          })}
        >
          {item.title}
        </Link>
        {item.subElementsCollection?.items.map((subItem, subItemIndex) => (
          <React.Fragment key={subItem?.sys.id}>
            {subItem?.categoriesCollection?.items.map((category, categoryIndex) => {
              if (itemIndex === 0 && subItemIndex === 0 && categoryIndex === 0) {
                return (
                  <div
                    key={category?.sys.id}
                    className="mb-[30px] rounded-[5px] bg-sg-light-sand bg-opacity-50 p-[15px]"
                  >
                    <span className="mb-5 w-full text-20-25-sg font-extralight text-sg-black">
                      {category?.title}
                    </span>
                    <div className="space-y-2.5">
                      {category?.subElementsCollection?.items.map((subCategory) =>
                        subCategory ? (
                          <Link
                            key={subCategory.sys.id}
                            href={subCategory.pageContent?.slug || '#'}
                            className="flex w-full items-center justify-between text-15-22-sg font-extralight text-sg-black"
                            onClick={() => trackNavItemClick(subCategory, '3rd_level')}
                          >
                            {subCategory.title}
                          </Link>
                        ) : null,
                      )}
                    </div>
                  </div>
                )
              }

              return (
                category && (
                  <button
                    key={category.sys.id}
                    className="mb-2.5 flex w-full items-center justify-between border-b border-sg-light-grey pb-2.5 text-15-22-sg font-extralight text-sg-black"
                    onClick={() => handleSubCategorySelect(category)}
                  >
                    {category.title}
                    <span className="text-sg-grey">
                      <Icon name="chevron-right" className="w-2.5" />
                    </span>
                  </button>
                )
              )
            })}
          </React.Fragment>
        ))}
      </div>
    )
  },
)

NavItem.displayName = 'NavItem'

export const MobileFlyout = memo(
  ({ isMobileFlyoutOpen, mobileNavData, handleClose }: MobileFlyoutProps) => {
    const [selectedSubCategory, setSelectedSubCategory] = useState<NavCategoryWithElements | null>(
      null,
    )
    const { dataLayer } = useGTM()

    const trackNavItemClick = useCallback(
      (item: NavigationElement | NavCategoryWithElements, level: string) => {
        dataLayer(DataLayerEnum.EVENT, 'nav_item_click', {
          method: 'navigation',
          navigation_level: level,
          navigation_item: item?.title || item?.subline,
        })
      },
      [dataLayer],
    )

    const handleSubCategorySelect = useCallback(
      (category: NavCategoryWithElements) => {
        setSelectedSubCategory(category)
        trackNavItemClick(category, '2rd_level')
      },
      [trackNavItemClick],
    )

    const handleSubCategoryBack = useCallback(() => setSelectedSubCategory(null), [])

    const memoizedNavItems = useMemo(
      () =>
        mobileNavData?.map((item, index) => (
          <NavItem
            key={item.sys.id}
            item={item}
            itemIndex={index}
            trackNavItemClick={trackNavItemClick}
            handleSubCategorySelect={handleSubCategorySelect}
          />
        )) || [],
      [mobileNavData, trackNavItemClick, handleSubCategorySelect],
    )

    return (
      <nav
        className={clsx(
          'fixed inset-0 -top-2.5 z-50 bg-primary-white transition-all duration-300',
          {
            'visible translate-y-0 opacity-100': isMobileFlyoutOpen,
            'invisible -translate-y-full opacity-0 delay-200': !isMobileFlyoutOpen,
          },
        )}
        style={{ height: '100dvh' }}
      >
        <div className="absolute top-0 left-0 right-0 z-10 bg-primary-white p-6">
          <div className="flex justify-between">
            <HeaderButtonBar />
            <button className="-m-4 p-4" onClick={handleClose}>
              <Icon name="cross" />
            </button>
          </div>
        </div>

        <div className="flex h-full flex-col justify-between overflow-y-auto pt-[70px]">
          <div className="p-6 pb-0">
            {memoizedNavItems}
            {isMobileFlyoutOpen && <BottomSection className="-mx-6" />}
          </div>
        </div>

        {selectedSubCategory && (
          <div
            className={clsx(
              'absolute inset-0 overflow-y-auto bg-primary-white p-6 pb-0 pt-[72px] transition-transform duration-300',
              {
                'translate-x-0 opacity-100': selectedSubCategory,
                'translate-x-full opacity-0': !selectedSubCategory,
              },
            )}
          >
            <div className="flex h-full flex-col justify-between">
              <MobileFlyoutSubMenu
                category={selectedSubCategory}
                onBack={handleSubCategoryBack}
                trackNavItemClick={trackNavItemClick}
              />
              {isMobileFlyoutOpen && <BottomSection className="-mx-6" />}
            </div>
          </div>
        )}
      </nav>
    )
  },
)

MobileFlyout.displayName = 'MobileFlyout'

export default MobileFlyout
