//SearchFlyoutDesktop in Search root component folder because it is rendered in ThirdLevel nav component
import { useEffect, useMemo } from 'react'
import { useAutocomplete } from '~/lib/Context/AutocompleteProvider'
import { SearchNotFoundResults } from './components/SearchNotFoundResults'
import clsx from 'clsx'
import { SearchResultSuggestions } from './components/SearchResultSuggestions'
import { SearchResultProductList } from './components/SearchResultProductList'
import { useRouter } from 'next/router'

type CioSearchFlyoutDesktopProps = {
  query: string
}

export const SearchFlyoutDesktop = ({ query }: CioSearchFlyoutDesktopProps) => {
  const { products, searchSuggestions, searchValue, loading, setQuery } = useAutocomplete()
  const router = useRouter()

  useEffect(() => {
    setQuery(query)
  }, [query, setQuery])

  const showNotFoundView = useMemo(() => {
    return (
      products.length === 0 &&
      searchSuggestions.length === 0 &&
      searchValue.trim().length > 1 &&
      !loading
    )
  }, [products, searchSuggestions, searchValue, loading])

  const handleSuggestionClick = async (suggestion: string) => {
    await router.push(`/search?q=${suggestion}`, undefined, {
      scroll: true,
    })
  }

  if (!searchValue || searchValue.trim().length === 0) {
    return null
  }

  return (
    <div className="relative mt-7.5 flex h-fit w-full justify-center pb-7.5">
      <div className="w-full">
        {showNotFoundView && (
          <SearchNotFoundResults
            className={clsx(!showNotFoundView && 'hidden')}
            searchValue={searchValue}
          />
        )}
        <div className="grid grid-cols-[1fr_3fr] gap-[49px]" data-cnstrc-autosuggest>
          <SearchResultSuggestions
            suggestions={searchSuggestions}
            handleSuggestionClick={handleSuggestionClick}
          />
          <SearchResultProductList products={products} />
        </div>
      </div>
    </div>
  )
}
