import { memo } from 'react'
import { Link } from '~/elements/Link/Link'

type HeaderButtonBarItemProps = {
  href: string
  title: string
  children: React.ReactNode
  className?: string
}

export const HeaderButtonBarItem = memo(
  ({ href, title, children, className }: HeaderButtonBarItemProps) => (
    <li className={className}>
      <Link href={href} title={title} prefetch={false}>
        {children}
      </Link>
    </li>
  ),
)

HeaderButtonBarItem.displayName = 'HeaderButtonBarItem'
