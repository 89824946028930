import React, { useCallback } from 'react'
import { Menu, MenuButton, MenuList, MenuItem } from '@reach/menu-button'
import { useTranslation } from 'next-i18next'
import { Image } from '~/elements/Image/Image'
import { possibleStores, Store } from '~/config/constants'
import { useRouter } from 'next/router'
import cookies from 'js-cookie'
import { useQueryClient } from '@tanstack/react-query'

export const MobileLanguage: React.FC = () => {
  const router = useRouter()
  const queryClient = useQueryClient()
  const { t } = useTranslation('languages/languages')
  const currentLand = process.env.NEXT_PUBLIC_COUNTRY_CODE
  const currentLang = router.locale?.slice(0, 2).toLowerCase()

  const currentStore = possibleStores.find(
    (store) => store.land === currentLand && store.lang.toLowerCase() === currentLang,
  )

  const handleLanguageChange = useCallback(
    async (country: Store) => {
      if (country.land === 'CH') {
        cookies.set('NEXT_LOCALE', country.lang.toLowerCase(), {
          expires: new Date(new Date().getTime() + 864000 * 1000),
          secure: process.env.NODE_ENV === 'production',
          sameSite: 'strict',
        })
        if (currentLand === 'CH') {
          queryClient.clear()
          await router.push('/', '/', { locale: country.lang.toLowerCase() })
        } else {
          window.location.href = country.url
        }
      } else {
        window.location.href = country.url
      }
    },
    [currentLand, queryClient, router],
  )

  const filteredStores = possibleStores
    ?.filter((item) =>
      currentLand === 'CH' ? item.lang.toLowerCase() !== currentLang : item.land !== currentLand,
    )
    ?.sort((a, b) => {
      if (a.land === currentLand) return -1
      if (b.land === currentLand) return 1
      return a.land < b.land ? -1 : 1
    })

  return (
    <div className="flex items-center">
      <Menu id="language-menu" isExpanded={true}>
        <MenuButton className="flex items-center gap-2.5">
          <Image
            src={`/images/Flags/${currentLand}.svg`}
            placeholder="empty"
            loading="lazy"
            width={20}
            height={20}
            alt={currentLand ?? 'flag-icon'}
          />
          <span className="font-extralight">{currentStore?.lang}</span>
        </MenuButton>
        <MenuList className="language-menu border-0 p-1 shadow-[0px_4px_24px_rgba(0,0,0,0.15)]">
          {filteredStores?.map((country) => (
            <MenuItem
              key={`${country.land}${country.lang}`}
              className="hover:bg-white flex w-full flex-row justify-between space-x-2"
              title={t(country.name)}
              onSelect={() => handleLanguageChange(country)}
            >
              <span>
                {t(country.name)} ({country.lang})
              </span>
              <Image
                src={`/images/Flags/${country.land}.svg`}
                placeholder="empty"
                loading="lazy"
                width={20}
                height={20}
                alt={country.land ?? 'flag-icon'}
              />
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </div>
  )
}
