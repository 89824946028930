import clsx from 'clsx'
import { Asset, NewsletterDisclaimer } from '@contentfulTypes'
import { Image } from '~/elements/Image/Image'
import { NewsletterForm } from './NewsletterForm'
import { Icon } from '~/elements/Icons/Icon'
import { Media } from '~/lib/Media'
import { DataLayerEnum, useGTM } from '~/hooks/useGTM'
import { useCallback } from 'react'

type NewsletterContentProps = {
  headline?: string | null
  imageDesktop?: Partial<Asset> | null
  imageMobile?: Partial<Asset> | null
  disclaimer?: NewsletterDisclaimer | null
  subscriptionBenefits?: string[]
  isFooter?: boolean
  isBanderole?: boolean
  className?: string
}

type ImageSettings = {
  data?: Asset | null
  sizes: string
}

const renderImage = (image: Partial<Asset> & ImageSettings) => (
  <Image
    src={image.url!}
    alt={image.description ?? ''}
    fill
    sizes={image.sizes}
    style={{ objectFit: 'cover' }}
    className="object-cover"
    priority={false}
    width={undefined}
    height={undefined}
  />
)

export const NewsletterContent = ({
  headline,
  imageDesktop,
  imageMobile,
  disclaimer,
  subscriptionBenefits,
  isFooter,
  isBanderole,
  className,
}: NewsletterContentProps) => {
  const { dataLayer } = useGTM()

  const handleSubmitTracking = useCallback(
    (email: string) => {
      dataLayer(DataLayerEnum.EVENT, 'sign_up', {
        method: isFooter ? 'footer_form' : isBanderole ? 'banderole_popup' : 'hp_box',
        user_data: {
          email_address: email,
        },
      })
    },
    [dataLayer, isFooter, isBanderole],
  )

  return (
    <>
      {!isFooter ? (
        <div className="relative w-full lg:w-1/2">
          <Media className="h-full" greaterThanOrEqual="lg">
            {imageDesktop &&
              renderImage({
                ...imageDesktop,
                sizes: '50vw',
              })}
          </Media>
          <Media className={isBanderole ? 'h-[105px]' : 'h-[205px]'} lessThan="lg">
            {imageMobile &&
              renderImage({
                ...imageMobile,
                sizes: '100vw',
              })}
          </Media>
        </div>
      ) : null}
      <div
        className={clsx(
          'flex h-full w-full',
          !isFooter && 'justify-center bg-sg-neon-yellow p-5 lg:w-1/2 lg:py-[80px]',
          className,
        )}
      >
        <div className="flex w-full max-w-[400px] flex-col gap-5 lg:gap-[30px]">
          <p
            className={clsx(
              'font-extralight',
              isFooter ? 'text-20-25-sg' : 'text-30-35-sg lg:text-40-45-sg',
            )}
          >
            {headline}
          </p>
          {!!subscriptionBenefits?.length && (
            <ul className="flex flex-col gap-2.5 font-extralight">
              {subscriptionBenefits.map((benefit) => (
                <li className="flex items-center gap-2" key={benefit}>
                  <Icon name="plus" className="h-[18px] w-[18px] flex-shrink-0 text-sg-black" />
                  <p className="text-15-22-sg">{benefit}</p>
                </li>
              ))}
            </ul>
          )}
          <NewsletterForm disclaimer={disclaimer} handleSubmitTracking={handleSubmitTracking} />
        </div>
      </div>
    </>
  )
}
