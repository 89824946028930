import { useMemo } from 'react'
import { Logo } from '../components/Logo'
import clsx from 'clsx'
import Search from '../../Search/Search'
import { FirstLevelMenu } from './components/FirstLevelMenu'
import { SecondLevelMenu } from './components/SecondLevelMenu'
import { useRouter } from 'next/router'
import { useNavigation } from '~/lib/Context/NavigationProvider'
import { HeaderButtonBar } from '../components/HeaderButtonBar'
import { NavigationElementWithSubElements, useHeaderQuery } from '@contentfulTypes'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { DEFAULT_LOCALE } from '~/config/constants'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { useAutocomplete } from '~/lib/Context/AutocompleteProvider'

type DesktopHeaderProps = {
  className?: string
  isBanderoleClosed: boolean
}

export const DesktopHeader = ({ className, isBanderoleClosed }: DesktopHeaderProps) => {
  const { searchValue, setQuery } = useAutocomplete()

  const {
    activeLevel,
    activeLevelIndex,
    isSearchFlyoutOpen,
    isHeaderSticky,
    closeHeaderFlyout,
    handleSearchFocus,
    displayLightTheme,
  } = useNavigation()

  const router = useRouter()
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })

  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE

  const { data } = useHeaderQuery(contentfulClient, {
    preview,
    locale: finalLocale,
  })

  const navData = useMemo(() => {
    const items =
      data?.pageCollection?.items[0]?.mainMegaNavigation?.firstLevelItemsCollection?.items

    return items?.filter((item): item is NavigationElementWithSubElements => item !== null) ?? []
  }, [data])

  const innerHeaderClasses = clsx(
    'flex items-center py-5 px-[30px] transition-all duration-300',
    isSearchFlyoutOpen || (activeLevel && activeLevelIndex !== -1) || !isBanderoleClosed
      ? 'rounded-t-[5px]'
      : 'rounded-[5px]',
    {
      'bg-sg-white': isHeaderSticky || isSearchFlyoutOpen || activeLevel,
      'bg-opacity-95 backdrop-blur-[5px]': activeLevel && activeLevelIndex !== -1,
    },
  )

  return (
    <nav className={className} role="navigation">
      <div className={innerHeaderClasses}>
        <Logo lightTheme={displayLightTheme} className="h-[25px] w-[100px]" />
        <FirstLevelMenu className="ml-[100px] pr-5" navData={navData} />
        <menu className="ml-auto flex w-full max-w-[360px] items-center justify-between">
          <Search
            lightTheme={displayLightTheme}
            value={searchValue}
            onFocus={handleSearchFocus}
            onClose={closeHeaderFlyout}
            onChange={setQuery}
            className="mr-auto w-full max-w-[200px]"
          />
          <HeaderButtonBar lightTheme={displayLightTheme} />
        </menu>
      </div>
      <div className="relative">
        <SecondLevelMenu navData={navData} />
      </div>
    </nav>
  )
}
